import React, { useState, useEffect } from "react";

import Layout from "../components/common/layout/layout";
import SEO from "../components/common/seo";

import ReactMarkdown from "react-markdown";
import NotFoundPage from "./404";
import { sendAmplitudeData } from "../utils/amplitude"
import { AmplitudeEvents } from "../types/amplitudeEvents"
import "aos/dist/aos.css";

const PricingPage = (context) => {
  useEffect(() => {
    window.scrollTo(0, 0);

    sendAmplitudeData(AmplitudeEvents.PRICING_PAGE_VISITED);

    const AOS = require("aos");
    AOS.init({
      duration: 1800,
      once: true
    });

    if (AOS) {
      AOS.refresh();
    }
  }, []);

  const [activePriceTierTab, setActivePriceTierTab] = useState('priceTier0');
  const headerData = context.pageContext.headerConfigurations;
  const footerData = context.pageContext.footerConfigurations;

  const pricingPageConfigs = context.pageContext.node;

  const [planSwitchType, setPlanSwitchType] = useState('annual');
  const [numberOfEditors, setNumberOfEditors] = useState(1);

  const incrementEditors = () => {
    if (numberOfEditors > 11) return;

    setNumberOfEditors(numberOfEditors + 1);
  }

  const decrementEditors = () => {
    if (numberOfEditors <= 1) return;

    setNumberOfEditors(numberOfEditors - 1);
  }

  const getPriceTierAmount = (priceTierUnitPrice) => {
    const originalPriceTierAmount = priceTierUnitPrice * numberOfEditors;

    return `$${originalPriceTierAmount}`;
  }

  if (!pricingPageConfigs) return <NotFoundPage />;

  return (
    pricingPageConfigs && <Layout title="Pricing" headerData={headerData} footerData={footerData}>
      { pricingPageConfigs.seo && <SEO page={pricingPageConfigs.slug} seo={pricingPageConfigs.seo} /> }

      <section className="pricing-section">
        <div className="content-layer">
          <h1 className="h1" data-aos="fade-up">{pricingPageConfigs.Heading}</h1>
          <p data-aos="fade-up" className="h2 section-body">{pricingPageConfigs.Description}</p>

          {/* <div data-aos="fade-up" className="plans-switcher noselect">
            <label>
              <em>Annually</em>
              <input type="checkbox"
                onClick={() => setPlanSwitchType(planSwitchType === 'annual' ? 'monthly' : 'annual')}
              />
              <span className="toggle-switch"></span>
              <em>Monthly</em>
            </label>
          </div> */}

          <h6 className="h3" data-aos="fade-up">{pricingPageConfigs.annualSavingsText}</h6>

          <div className={`price-tiers ${activePriceTierTab}-tab-open`}>
            <div className="tabs">
              {
                pricingPageConfigs.price_tiers.map((priceTier, index) => {
                  return (
                    <label className={`price-tier-tab priceTier${index}-tab`} key={priceTier.title}>
                      <input type="radio" name="price-tier"
                        checked={`priceTier${index}` === activePriceTierTab}
                        value={priceTier.title}
                        onChange={() => setActivePriceTierTab(`priceTier${index}`)}
                      />
                      {priceTier.title}
                    </label>
                  )
                })
              }
            </div>

            {
              pricingPageConfigs.price_tiers.map((priceTier, index) => {
                return (
                  <div data-aos="fade-up" data-aos-once="true" data-aos-delay={`${index > 0 ? 300 * index : 0}`} className={`price-tier-item priceTier${index}-tab`} key={priceTier.title}>
                    <h4>{priceTier.title}</h4>

                    { priceTier.description && <p className="description">{priceTier.description}</p> }

                    <p className="subscription-cost">
                      
                    </p>

                    { priceTier.offer_text && <p className="offer-text">{priceTier.offer_text}</p> }

                    { priceTier.coming_soon && <p className="coming-soon-text">{priceTier.coming_soon}</p> }

                    <ul className="features-list">
                      {
                        priceTier.features.map((priceTierItem, index) => {
                          return (
                            <li className="features" key={priceTierItem.feature_title}>
                              <ul className="feature-group">
                                <li className="feature-heading">{priceTierItem.feature_title}</li>
                                {
                                  priceTierItem.feature.map((item, index) => {
                                    return item.is_enabled &&
                                    <li key={item.feature} className={`feature ${item.feature === 'Editors' ? 'center-align-items': ''}`}>
                                      <p className="title-wrapper">
                                        <span className={`title ${item.tipsy ? 'has-tipsy' : ''}`}>
                                          <span className='feature-title'>{item.feature}</span>
                                          {item.tipsy && <span className="tipsy">{item.tipsy}</span>}
                                          {item.disclaimer && <span className="disclaimer">{item.disclaimer}</span>}
                                          {item.badge && <span className="badge">{item.badge}</span>}
                                        </span>
                                      </p>

                                      <p className={`quota ${item.feature === 'Editors' ? 'has-quantity-selection noselect': ''}`}>
                                        {
                                          item.quota &&
                                          <span className={`quota-text ${item.is_quota_text_highlighted ? 'highlight': ''}`}>
                                            {item.quota && (item.feature === 'Editors' ? (numberOfEditors < 10 ? '0' + numberOfEditors : numberOfEditors) : item.quota)}
                                          </span>
                                        }

                                        {
                                          item.feature === 'Editors' &&
                                          <span className="quantity-selector">
                                            <em className={`${numberOfEditors === 12 ? 'disabled': ''}`} onClick={() => incrementEditors()} role="button" tabIndex={0}>
                                              <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42"><g isolation="isolate"><g transform="translate(12 12)"><rect fill="#fff" width="4" height="18" rx="2" transform="translate(7)"/><rect fill="#fff" width="4" height="18" rx="2" transform="translate(18 7) rotate(90)"/></g></g></svg>
                                            </em>
                                            <em className={`${numberOfEditors === 1 ? 'disabled': ''}`} onClick={() => decrementEditors()} role="button" tabIndex={0}>
                                              <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42"><g isolation="isolate"><g transform="translate(12 19)"><rect fill="#fff" width="4" height="18" rx="2" transform="translate(18) rotate(90)"/></g></g></svg>
                                            </em>
                                          </span>
                                        }

                                        {item.show_tick && <span className="tick"></span>}
                                      </p>
                                    </li>
                                  })
                                }
                              </ul>
                            </li>
                          )
                        })
                      }
                    </ul>

                    {
                      priceTier?.price_tier_cta?.button_text &&
                      <a href={priceTier.price_tier_cta.url} className="cta-button" target={priceTier.price_tier_cta.target}>
                        {priceTier.price_tier_cta.button_text}
                      </a>
                    }

                  </div>
                )
              })
            }
          </div>
        </div>

        <div className="background-layer" role="presentation">
          <div data-aos="animate-art-work-cylindrical-light" className="art-work-cylindrical-light"></div>
          <div data-aos="animate-triangle-pattern-sand-pricing" className="triangle-pattern-sand"></div>

          <div data-aos-offset="600" data-aos-anchor=".abstract-art-animation-anchor" data-aos="animate-triangle-sand" className="triangle-sand"></div>
          <div data-aos-offset="600" data-aos-anchor=".abstract-art-animation-anchor" data-aos="animate-triangle-multi-lines" className="triangle-multi-lines"></div>
          <div data-aos-offset="600" data-aos-anchor=".abstract-art-animation-anchor" data-aos="animate-triangle-arrow-pattern-pink" className="triangle-arrow-pattern-pink"></div>
          <div data-aos-offset="600" data-aos-anchor=".abstract-art-animation-anchor" data-aos="animate-art-work-cylindrical-dark" className="art-work-cylindrical-dark"></div>
          <span className="abstract-art-animation-anchor"></span>

          <div className="triangle-arrow-pattern-pastel-pink"></div>
        </div>
      </section>

      <section className="faq-section">
        {
          pricingPageConfigs.pricing_faq_section &&
          <>
            <h2 className="h1">{pricingPageConfigs.pricing_faq_section.section_heading}</h2>
            <p className="h2">{pricingPageConfigs.pricing_faq_section.section_body}</p>

            <ul>
              {
                pricingPageConfigs.pricing_faq_section.question_and_answers.question_and_answer.map((qaSection, index) => {
                  return (
                    <li key={`question-${index}`}>
                      <p className="question">{qaSection.heading}</p>
                      <p className="answer">{qaSection.description}</p>
                    </li>
                  )
                })
              }
            </ul>
          </>
        }

        <ReactMarkdown className="h2 section-description">
          {pricingPageConfigs.link_to_help_center}
        </ReactMarkdown>
      </section>
    </Layout>
  )
}

export default PricingPage;
